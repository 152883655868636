@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {

  .text-intro-header{
    font-size: clamp(0.8rem , 0.8rem + 9vw , 6.5rem);
  }

  .text-experience-header{
    font-size: clamp(0.5rem , 0.4rem + 3vw , 1.25rem);
  }

  .opacity-0{
    opacity: 0;
  }

  .opacity-100{
    opacity: 1;
  }

  .text-section-headers{
    font-size: clamp(0.8rem , 0.4rem + 5.2vw , 2.25rem);
  }

  .list-image-hobbies{
    list-style-image: url('./assests/noun-hobbies-3683675.svg');
  }

  .list-image-university{
    list-style-image: url('./assests/noun-segment-6560644.svg');
  }

  .list-image-frontend{
    list-style-image: url('./assests/noun-front-end-5072094.svg');
  }

  .list-image-code{
    list-style-image: url('./assests/noun-internship-6530862.svg');
  }
  .list-image-learning{
    list-style-image: url('./assests/noun-learning-6493040.svg');
  }

.text-navbar{
   font-size: clamp(0.8rem , 0.5rem + 0.6vw  , 2rem);

  }
  .text-intro-desc{
     font-size: clamp(0.7rem , 0.3rem + 1vw  , 2rem);
     line-height: clamp(0.3, 2 ,1.75);
  }

  .text-section-desc {
    font-size: clamp(0.7rem , 0.25rem + 0.8vw  , 1rem);
    line-height: clamp(0.4, 2 ,1.75);
 }


 .text-archieve-desc{
  font-size: clamp(0.8rem , 0.25rem + 0.8vw  , 1rem);
  line-height: clamp(0.4, 2 ,1.75);
 }


  .writing-mode {
    writing-mode: vertical-rl;
  }

  .p-invert {
    padding: revert;
  }

  .auto-grid {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }

  .animateOnView {
    animation-timeline: view(y);
    animation-range-start: cover 20vh;
    animation-range-end: cover 30vh;
  }

  .scroll-reveal {
    color: hsl(0 0% 100% / 0.2);
    background-clip: text;
    background-repeat: no-repeat;
    background-size: 0% 100%;
    background-image: linear-gradient(90deg, white, white);
    animation-timeline: view();
    animation-range-start: cover 10vh;
    animation-range-end: cover 40vh;
  }

  .scrollbar::-webkit-scrollbar {
    width: 10px;
    height: 3px;
  }

  .scrollbar::-webkit-scrollbar-track {
    border-radius: 50vh;
    background: rgb(79, 92, 111);
    opacity: 0;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background: rgb(56 189 248);
    border-radius: 60vh;
    border: 5px solid transparent;
  }

  .scrollbar::-webkit-scrollbar-thumb:hover {
    background: transparent;
  }
}

@layer animations {
  .translateSE {
    transform: translate(200px, 50%);
  }
  .translateN {
    transform: translateY(200px);
  }
  .translateS {
    transform: translateY(-200px);
  }
  .translateSW {
    transform: translate(-200px, 50%);
  }
  .translateNW {
    transform: translate(-200px, -50%);
  }
  .translateNE {
    transform: translate(200px, -50%);
  }

  .translateSE2 {
    transform: translate(300px, 25%);
  }
  .translateSW2 {
    transform: translate(-400px, 25%);
  }
  .translateNW2 {
    transform: translate(-300px, -25%);
  }
  .translateNE2 {
    transform: translate(300px, -50%);
  }

  .translateE {
    transform: translateX(350px);
  }
  .translateW {
    transform: translateX(-350px);
  }
}

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
